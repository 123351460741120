@import '_variables';
 @import '_menu';
 html {
	 min-height: 100%;
	 font-size: 18px;
}
 @media screen and (min-width: 600px) {
	 html {
		 font-size: 22px;
	}
}
 body {
	 margin: 0;
	 padding: 0;
	 background-color: #fff;
	 color: #333;
	 font-family: 'Crimson Text', serif;
	 -webkit-font-smoothing: antialiased;
	 -moz-osx-font-smoothing: grayscale;
}
 p {
	 line-height: 1.4;
}
 ol {
	 line-height: 1.4;
}
 li {
	 margin-bottom: 0.5rem;
}
 strong {
	 font-weight: 700;
}
 a {
	 color: #005596;
}
 a:hover {
	 color: #002c4e;
}
 img {
	 width: 100%;
	 height: auto;
	 vertical-align: middle;
}
 .header {
	 max-width: 1200px;
	 margin: 0 auto;
	 padding: 0 16px;
	 display: flex;
	 justify-content: space-between;
	 align-items: flex-start;
	 flex-direction: column;
}
 @media screen and (min-width: 600px) {
	 .header {
		 flex-direction: row;
		 align-items: center;
	}
}
 .header__link {
	 text-decoration: none;
}
 .header__title {
	 color: #333;
	 font-size: 44px;
	 font-weight: 300;
	 position: relative;
	 margin-top: 2px;
	 margin-bottom: 43px;
}
 .header__title-subtitle {
	 position: absolute;
	 font-size: 21px;
	 bottom: -26px;
	 left: 0;
	 right: 0;
	 text-align: center;
	 border-top: 1px solid #333;
}
 .main {
	 background: #f6f6f6;
	 margin: 0 auto;
}
 .footer {
	 background-color: #333;
	 color: #fff;
	 padding: 32px 16px;
	 display: flex;
	 justify-content: center;
}
 .footer a {
	 color: #fff;
}
 .footer span {
	 padding: 0 16px;
}
 .start__header {
	 text-align: center;
	 margin: 0 auto;
	 max-width: 1200px;
	 padding: 80px 0 90px;
}
 .start__header h1 {
	 font-size: 3rem;
	 line-height: 1;
	 margin: 0 0 1rem 0;
}
 .start__header p {
	 margin: 0;
	 padding: 0 2rem;
}
 .start {
	 max-width: 1200px;
	 margin: 0 auto;
	 padding-bottom: 100px;
}
 .start__row {
	 display: flex;
	 justify-content: center;
	 flex-direction: column;
}
 @media screen and (min-width: 600px) {
	 .start__row {
		 flex-direction: row;
	}
}
 .start__item {
	 flex: 1;
	 min-height: 250px;
	 padding: 16px;
	 display: flex;
	 text-decoration: none;
	 position: relative;
	 background-size: cover;
	 background-position: center;
	 color: #fff;
	 align-items: flex-end;
	 text-align: left;
	 justify-content: flex-start;
	 margin: 10px;
	 border-radius: 4px;
	 overflow: hidden;
}
 @media screen and (min-width: 600px) {
	 .start__item {
		 min-height: 300px;
	}
}
 .start__item::before {
	 content: '';
	 position: absolute;
	 top: 0;
	 left: 0;
	 right: 0;
	 bottom: 0;
	 background-color: #333;
	 opacity: 0;
	 transition: opacity 200ms ease-in-out;
}
 .start__item::after {
	 content: '';
	 position: absolute;
	 top: 50%;
	 left: 0;
	 right: 0;
	 bottom: 0;
	 background-image: linear-gradient(to bottom, transparent, #000);
	 opacity: 0.8;
}
 .start__item:hover::before {
	 opacity: 0.5;
}
 .start__item:hover .start__item__content {
	 color: #fff;
}
 .start__item__content {
	 position: relative;
	 transition: color 200ms ease-in-out;
	 z-index: 2;
}
 .start__item h2, .start__item p {
	 margin: 0;
}
 .article {
	 max-width: 1200px;
	 margin: 0 auto;
}
 .article__header {
	 padding: 32px 0;
	 text-align: center;
}
 .article__header h1 {
	 font-size: 3rem;
	 line-height: 1;
	 margin: 0;
}
 .article__header h2 {
	 margin: 0;
	 color: #555;
	 font-size: 1.6rem;
	 font-weight: 400;
	 line-height: 1.2;
	 margin-top: 0.5rem;
}
 .article__header__image {
	 margin-top: 40px;
	 max-height: 360px;
	 object-fit: cover;
	 object-position: center;
	 max-width: 1000px;
}
 .article__section {
	 max-width: 700px;
	 margin: 0 auto;
	 padding: 0 16px 60px;
}
 .article__section--wide {
	 max-width: 1000px;
}
 .article__section p.preamble {
	 font-size: 1.4rem;
	 line-height: 1.2;
}
 .table {
	 background-color: rgba(255, 255, 255, 1);
	 border-spacing: 0;
	 margin-bottom: 40px;
	 width: 100%;
}
 @media screen and (min-width: 600px) {
	 .table {
		 box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
	}
}
 .table__head td {
	 background-color: rgba(255, 255, 255, 1);
	 border-bottom: 4px solid rgba(64, 64, 64, 1);
	 font-weight: 700;
}
 .table__body tr:nth-child(odd) {
	 background-color: rgba(242, 242, 242, 1);
}
 .table__body td:first-child {
	 font-weight: 500;
}
 td {
	 padding: 8px;
	 border-left: 1px solid rgba(217, 217, 217, 1);
	 border-top: 1px solid rgba(217, 217, 217, 1);
}
 @media only screen and (max-width: 400px) {
	 td {
		 font-size: 14px;
	}
}
 @media only screen and (min-width: 600px) {
	 td:first-child {
		 border-left: 0;
	}
}
 @media only screen and (min-width: 600px) {
	 tr:first-child td {
		 border-top: 0;
	}
}
 @media only screen and (max-width: 599px) {
	 table, thead, tbody, th, td, tr {
		 display: block;
	}
	 thead tr {
		 position: absolute;
		 top: -9999px;
		 left: -9999px;
	}
	 tr {
		 border-bottom: 16px solid #f6f6f6;
	}
	 td {
		 position: relative;
		 padding-left: 50%;
		 border-right: 1px solid rgba(217, 217, 217, 1);
	}
	 td:first-child {
		 font-weight: 700;
	}
	 td:last-child {
		 border-bottom: 1px solid rgba(217, 217, 217, 1);
	}
	 td:before {
		 position: absolute;
		 top: 7px;
		 left: 6px;
		 width: 45%;
		 white-space: nowrap;
	}
	 .table td:nth-of-type(1):before {
		 content: 'Leverantör';
	}
	 .table td:nth-of-type(2):before {
		 content: 'Gratisperiod';
	}
	 .table td:nth-of-type(3):before {
		 content: 'Priser från';
	}
	 .table td:nth-of-type(4):before {
		 content: 'Support';
	}
}
 

@media screen and (min-width: 767px) {
  .menu {
    position: relative;
    z-index: 99;
 }
}
.menu .active {
  text-decoration: none;
  color: #333;
  pointer-events: none;
  font-weight: 700;
}
@media screen and (min-width: 767px) {
  .menu .active {
    font-weight: 400;
 }
}
.menu__foldout__toggle.active::before {
  right: 16px;
}
.menu__toggle {
  -webkit-appearance: none;
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  padding: 8px 16px;
  border: 1px solid #666;
  border-radius: 3px;
  background-color: #fff;
  z-index: 9;
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .menu__toggle {
    display: none;
 }
}
.menu__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
  z-index: 8;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.menu__list--open {
  transform: translateY(0);
}
@media screen and (min-width: 767px) {
  .menu__list {
    flex-direction: row;
    position: relative;
    transform: translateY(0);
 }
}
.menu__list li {
  margin-bottom: 0;
}
.menu__list a {
  color: #333;
  text-decoration: none;
}
.menu__list__item {
  position: relative;
  padding: 0 8px;
  margin: 0;
}
.menu__list__item:hover .menu__foldout {
  display: block;
}
.menu__list__link {
  position: relative;
  display: block;
  padding: 8px;
}
@media screen and (min-width: 767px) {
  .menu__list__link {
    padding: 16px 8px;
 }
}
@media screen and (min-width: 767px) {
  .menu__list__link.active::before {
    content: '';
    position: absolute;
    bottom: 8px;
    height: 2px;
    left: 8px;
    right: 8px;
    background-color: #777;
 }
}
.menu__foldout__toggle {
  padding-right: 16px;
  margin-right: 6px;
}
@media screen and (min-width: 767px) {
  .menu__foldout__toggle:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #777;
    position: absolute;
    top: 23px;
    right: 0px;
 }
}
.menu__foldout {
  list-style: none;
}
@media screen and (min-width: 767px) {
  .menu__foldout {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.2);
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px;
 }
}
.menu__foldout li:first-child {
  display: none;
}
@media screen and (min-width: 767px) {
  .menu__foldout li:first-child {
    display: block;
 }
}
.menu__foldout__link {
  display: block;
  padding: 8px 0;
  white-space: nowrap;
}
@media screen and (min-width: 767px) {
  .menu__foldout__link.active {
    font-weight: 700;
 }
}

