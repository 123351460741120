/* $mobileSize: 767px;

.menu {
  @media screen and (min-width: $mobileSize) {
    position: relative;
    z-index: 99;
  }
}

.menu .active {
  text-decoration: none;
  color: #333;
  pointer-events: none;
  font-weight: 700;

  @media screen and (min-width: $mobileSize) {
    font-weight: 400;
  }
}

.menu__foldout__toggle.active {
  &::before {
    right: 16px;
  }
}

.menu__toggle {
  -webkit-appearance: none;
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  padding: 8px 16px;
  border: 1px solid #666;
  border-radius: 3px;
  background-color: #fff;
  z-index: 9;
  cursor: pointer;

  @media screen and (min-width: $mobileSize) {
    display: none;
  }
}

.menu__list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: center;

  background-color: white;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;

  z-index: 8;

  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;

  &--open {
    transform: translateY(0);
  }

  @media screen and (min-width: $mobileSize) {
    flex-direction: row;
    position: relative;
    transform: translateY(0);
  }

  li {
    margin-bottom: 0;
  }

  a {
    color: #333;
    text-decoration: none;
  }
}

.menu__list__item {
  position: relative;
  padding: 0 8px;
  margin: 0;

  &:hover .menu__foldout {
    display: block;
  }
}

.menu__list__link {
  position: relative;
  display: block;
  padding: 8px;

  @media screen and (min-width: $mobileSize) {
    padding: 16px 8px;
  }

  &.active {
    &::before {
      @media screen and (min-width: $mobileSize) {
        content: '';
        position: absolute;
        bottom: 8px;
        height: 2px;
        left: 8px;
        right: 8px;
        background-color: #777;
      }
    }
  }
}

.menu__foldout__toggle {
  padding-right: 16px;
  margin-right: 6px;

  &:after {
    @media screen and (min-width: $mobileSize) {
      content: '';

      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-top: 5px solid #777;

      position: absolute;
      top: 23px;
      right: 0px;
    }
  }
}

.menu__foldout {
  list-style: none;

  @media screen and (min-width: $mobileSize) {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.2);
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px;
  }

  li {
    &:first-child {
      display: none;

      @media screen and (min-width: $mobileSize) {
        display: block;
      }
    }
  }
}

.menu__foldout__link {
  display: block;
  padding: 8px 0;
  white-space: nowrap;

  &.active {
    @media screen and (min-width: $mobileSize) {
      font-weight: 700;
    }
  }
} */
